// 文档: https://developers.google.com/pay/api/web/guides/tutorial?hl=zh-cn#event-handler；
import { addLog } from '@/api/common';
import { deviceFingerprintManager } from '@/plugins/deviceFingerprintManager';

export const GOOGLE_LANG = {
  'zh-cn': 'zh',
  'zh-tw': 'zh',
};
let paymentsClient = null;

export const GooglePay = {
  init: (GOOGLE_PAY, { transactionInfo, gatewayName, gatewayMerchantId, merchantId, language, website, merchantNo, externalOrderId, gaData, subscription }, container, form, callback, notSupportGooglePayHandler, allowedCardNetworks) => {
    const baseRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
    };
    // const allowedCardNetworks = ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'];
    // const allowedCardNetworks = ['MASTERCARD', 'VISA'];
    console.log(allowedCardNetworks, 'allowedCardNetworks');
    // TODO:支持配置卡类型，不在写死，但是有个问题就是AME 他们返回的是AE,且这个问题不能强制通道做修改，整个通道域都是AE，改的话变动太多
    let _allowedCardNetworks = [];
    if (allowedCardNetworks && allowedCardNetworks.length > 0) {
      _allowedCardNetworks = allowedCardNetworks.map(item => {
        if (item === 'AE') {
          return 'AMEX';
        } else {
          return item;
        }
      });
    } else {
      _allowedCardNetworks = ['MASTERCARD', 'VISA'];
    }
    const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];
    const baseCardPaymentMethod = {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: _allowedCardNetworks,
      },
    };
    const tokenizationSpecification = {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: gatewayName,
        gatewayMerchantId, // 商户号
      },
    };
    const cardPaymentMethod = Object.assign(
      { tokenizationSpecification },
      baseCardPaymentMethod,
    );
    const getGoogleIsReadyToPayRequest = () => {
      return Object.assign(
        {},
        baseRequest,
        {
          allowedPaymentMethods: [baseCardPaymentMethod],
        },
      );
    };
    const getGooglePaymentDataRequest = () => {
      const paymentDataRequest = Object.assign({}, baseRequest);
      paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
      paymentDataRequest.transactionInfo = getGoogleTransactionInfo(); // TODO
      paymentDataRequest.merchantInfo = {
        // @todo a merchant ID is available for a production environment after approval by Google
        // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
        // merchantId: '12345678901234567890',
        merchantId,
        merchantName: website, // 应用网址
        merchantOrigin: website, // 应用网址
      };
      // paymentDataRequest.paymentDataCallbacks = {};
      return paymentDataRequest;
    };
    const addGooglePayButton = () => {
      const paymentsClient = getGooglePaymentsClient();
      const button =
          paymentsClient.createButton({
            onClick: onGooglePaymentButtonClicked,
            allowedPaymentMethods: [baseCardPaymentMethod],
            buttonType: subscription ? 'subscribe' : 'buy',
            buttonSizeMode: 'fill',
            buttonLocale: GOOGLE_LANG[language] || language,
          });
      const $container = document.querySelector(container);
      $container.innerHTML = '';
      $container.appendChild(button);
    };
    const getGoogleTransactionInfo = () => {
      return transactionInfo;
    };
    // const prefetchGooglePaymentData = () => {
    //   const paymentDataRequest = getGooglePaymentDataRequest();
    //   // transactionInfo must be set but does not affect cache
    //   paymentDataRequest.transactionInfo = {
    //     totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
    //     currencyCode: 'USD',
    //   };
    //   const paymentsClient = getGooglePaymentsClient();
    //   paymentsClient.prefetchPaymentData(paymentDataRequest);
    // };

    // 选择GooglePay后 将回调发送埋点
    const sendRiskDeviceFingerPrint = (data) => {
      const param = {
        m: externalOrderId || '',
        timeStamp: Date.now(),
        e: 'external_sdk_interact',
        r: data,
      };
      deviceFingerprintManager.send(param);
    };

    const onGooglePaymentButtonClicked = () => {
      try {
        window.gtag('event', 'click_payment_button', gaData);
      } catch (error) {}
      // 验证表单
      if (form) {
        form.validate((valid) => {
          if (valid) {
            const paymentDataRequest = getGooglePaymentDataRequest();
            paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
            const paymentsClient = getGooglePaymentsClient();
            paymentsClient.loadPaymentData(paymentDataRequest)
              .then((paymentData) => {
                // handle the response
                processPayment(paymentData);
                sendRiskDeviceFingerPrint(paymentData);
              })
              .catch((err) => {
                // show error in developer console for debugging
                console.error('loadPaymentData', err);
                sendRiskDeviceFingerPrint(err);
                // Log
                try {
                  addLog({
                    merchantNo,
                    externalOrderId,
                    message: 'Google Pay loadPaymentData Error: ' + JSON.stringify(err),
                  });
                } catch (error) {
                  addLog({
                    merchantNo,
                    externalOrderId,
                    message: 'Google Pay loadPaymentData Error: ',
                  });
                }
              });
          }
        });
      } else {
        const paymentDataRequest = getGooglePaymentDataRequest();
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
        const paymentsClient = getGooglePaymentsClient();
        paymentsClient.loadPaymentData(paymentDataRequest)
          .then((paymentData) => {
            // handle the response
            processPayment(paymentData);
            sendRiskDeviceFingerPrint(paymentData);
          })
          .catch((err) => {
            // show error in developer console for debugging
            console.error('loadPaymentData', err);
            sendRiskDeviceFingerPrint(err);
            // Log
            try {
              addLog({
                merchantNo,
                externalOrderId,
                message: 'Google Pay loadPaymentData Error: ' + JSON.stringify(err),
              });
            } catch (error) {
              addLog({
                merchantNo,
                externalOrderId,
                message: 'Google Pay loadPaymentData Error: ',
              });
            }
          });
      }
    };
    const processPayment = (paymentData) => {
      // show returned data in developer console for debugging
      // @todo pass payment token to your gateway to process payment
      const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
      if (callback) {
        callback(paymentToken);
      }
    };
    const getGooglePaymentsClient = () => {
      if (paymentsClient === null) {
        paymentsClient = new window.google.payments.api.PaymentsClient({ environment: GOOGLE_PAY.ENV });
      }
      return paymentsClient;
    };
    const onGooglePayLoaded = () => {
      const paymentsClient = getGooglePaymentsClient();
      paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
        .then((response) => {
          if (response.result) {
            addGooglePayButton();
            // @todo prefetch payment data to improve performance after confirming site functionality
            // prefetchGooglePaymentData();
          } else {
            if (notSupportGooglePayHandler) {
              notSupportGooglePayHandler();
            }
          }
        })
        .catch((err) => {
          // show error in developer console for debugging
          if (notSupportGooglePayHandler) {
            notSupportGooglePayHandler();
          }
          console.error('isReadyToPay', err);
          // Log
          try {
            addLog({
              merchantNo,
              externalOrderId,
              message: 'Google Pay isReadyToPay Error: ' + JSON.stringify(err),
            });
          } catch (error) {
            addLog({
              merchantNo,
              externalOrderId,
              message: 'Google Pay isReadyToPay Error: ',
            });
          }
        });
    };
    onGooglePayLoaded();
  },
};
